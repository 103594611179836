import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Super Channels",
  "route": "/super-channels"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Super Channels`}</h1>
    <p>{`When subscribing to a channel, Smartlike users choose whether they want to follow a creator on a particular platform or subscribe to their Super Channel that aggregates their content no matter where it is published.`}</p>
    <p><strong parentName="p">{`Increased creator visibility:`}</strong>{` Users don't miss publications on platforms they aren't signed up to. Authors can decentralize their work among multiple publishers, reduce duplication and investment into learning to live with specific platform algorithms. Less popular publishers get more visibility and better chances to offer competitive service and conditions.`}</p>
    <p><strong parentName="p">{`More freedom for all:`}</strong>{` Platforms can choose a share to take from micro-donations to their hosted content to get less dependent from third parties like advertisers and private data collectors. Creators have their channels decentralized and can even migrate from one platform to another without loosing their audience and investment into channel building. Users will benefit from the improved balance of power between creators and platforms in getting better service and more privacy.`}</p>
    <p><strong parentName="p">{`How it works?`}</strong>{` Super Channels are created either by creators who sign their channels or by their audience when they subscribe and link multiple channels together. Super Channel connections are stored on the public ledger and can be freely used by third parties like all other Smartlike data.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      